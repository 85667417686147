
import React from 'react'
import './Product.css'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import bit_prd_hr_line from '../images/bit-product-hr-line-img.png';

function Product() {

    useEffect(() => {
        // document.title = 'Product';
        

    }, []);
    return (
        <div>



<Helmet>
                        <title>SalesOptim CRM, ERP, Mobile App | Brightlink</title>
                        <meta
                            name="description"
                            content="SalesOptim by Brightlink Info Technologies offers powerful CRM,
ERP, project management, and recruitment portal solutions to streamline operations, boost
productivity, and drive business growth with integrated, user-friendly technology."
                        />
                        <meta
                            name="keywords"
                            content="CRM products, customer relationship management, business solutions, Brightlink Info Technologies, CRM software"
                        />
                        <link rel="canonical" href="https://yourwebsite.com/home" />
                        <meta name="robots" content="index, follow" />
                        <meta name="author" content="Brightlink Info Technologies" />
                        <meta name="publisher" content="Brightlink Info Technologies" />
                    </Helmet>


            <section className='bit-ourprd-sec1-main'>

                <div className='bit-ourprd-sec1-main-title-div'>

                    <p className='bit-ourprd-sec1-main-p1'>OUR</p>
                    <p className='bit-ourprd-sec1-main-p2'>PRODUCT</p>
                </div>

                <div className='bit-ourprd-sec1-main-hr-img-main'>
                    <img src={bit_prd_hr_line} alt="" className='bit-ourprd-sec1-main-hr-img' />
                </div>
                <div className='bit-ourprd-sec1-main-btn1-main'>
                    <a href='#bit-product-for-view-prd' className='bit-ourprd-sec1-main-btn1'>View all products</a>

                 </div>

            </section>

            {/* <section className='prodsec1-main'>
                <div className=' text-start' id='prodsec1-main-crd'>



                    <div class="card"  >
                        <div class="card-body">
                            <h5 class="card-title" id='sec1-prod-title1'>OUR PRODUCT</h5>

                        </div>
                    </div>

                    <a className='btn' href='#prod-sec3-listdiv' id='prodsec1-main-btn' >View all products</a>
                </div>


                <img src='../img/product/img1.png' className='position-absolute end-0' id='prodsec1-main-img'></img>

            </section> */}

            {/***********************************************/}


            {/* <section className='sectioncr6 d-flex align-items-center  ' id='sectionprd2'>
                <div className='col-md-8 ml-5 mt-5 h-100 text-start'>
                    <div class="row boxsh " id='prodsec2-boxset-left'>
                        <div class="row col-md-12 mb-4 p-0" data-aos="fade-up">
                            <div class="col-md-8 w-100 p-0">
                                <div class="card h-100 " >
                                    <div class="card-body p-5 h-100" id='sectionprd2-boxs'>
                                        <h5 class="">CRM</h5>
                                        <p><b>Brightlink Softwares offers an innovative Lead Management Software designed to streamline and optimize the process of acquiring and nurturing potential customers. This robust software empowers businesses to efficiently capture, track, and manage leads throughout the entire sales cycle. With its user-friendly interface, the Brightlink Lead Management Software enables teams to prioritize and assign leads, ensuring a seamless and organized workflow.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 w-100 p-0">
                                <img src="/images/Rectangle172.png" alt="" className='sec6img' />
                            </div>
                        </div>
                    </div>
                    <div class="row boxsh">
                        <div class="row col-md-12 mb-4 p-0" data-aos="fade-up">
                            <div class="col-md-8 w-100 p-0">
                                <div class="card h-100" >
                                    <div class="card-body p-5 h-100" id='sectionprd2-boxs'>
                                        <h5 class="">ERP</h5>
                                        <p><b>Small & medium businesses use ERP software to improve communication across departments and optimize business processes by providing easy visibility of all operations.ERP software also helps small businesses integrate both their financial information and customer information into one system.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 w-100 p-0">
                                <img src="/images/Rectangle187.png" alt="" className='sec6img' />
                            </div>
                        </div>
                    </div>
                    <div class="row boxsh" >
                        <div class="row col-md-12 mb-4 p-0" data-aos="fade-up">
                            <div class="col-md-8 w-100 p-0" >
                                <div class="card h-100"  >
                                    <div class="card-body p-5 h-100" id='sectionprd2-boxs'>
                                        <h5 class="">HRM</h5>
                                        <p><b>Our HRM application seamlessly manages employee records, tracks attendance, monitors leave, and maintains detailed daily activity logs. with user-friendly interfaces, it ensures efficient record-keeping and simplifies salary management. Enhance workforce productivity and streamline HR processes with our comprehensive solution, tailored to meet your organization's needs.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 w-100 p-0">
                                <img src="/images/Rectangle188.png" alt="" className='sec6img' />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/*-----------------section3--------------------- */}



            {/* 
            <section className='prod-sec3 '>

                <p className='prod-sec3-heding1'>Other Services We Offers –</p>
                <div class="prodsec3-row1 row d-flex align-items-center " >
                    <div class="prodsec3-row1-part1 col-8 bg-transparent">
                        <div class="card w-100 bg-transparent border-0">
                            <div class="card-body  border-0">

                                <div className='text-start '>

                                    <p className='prod-sec3-heding2'>Crop mapping project</p>

                                    <p className='prod-sec3-p1'>CropGen provides a suite of weather API services that give farmers real-time and historical weather data to optimize crop management and mitigate weather risks. Farmers can use these services to improve irrigation schedules, plan pest control, select crop varieties, protect crops from extreme weather, and analyze weather patterns to make informed planting decisions.</p>


                                </div>



                            </div>
                        </div>


                    </div>

                    <div class="prodsec3-row1-part2 col-4">
                        <img src="/img/product/img2.png" class="img-fluid " data-aos=""></img>
                    </div>
                </div>


                <div class="prodsec3-row2 row d-flex align-items-center">

                    <div class="col-4">
                        <img src="/img/product/img3.png" class="img-fluid" data-aos=""></img>
                    </div>

                    <div class="col-8">
                        <div class="card w-100 bg-transparent border-0">
                            <div class="card-body border-0">

                                <div className='text-start '>

                                    <p className='prod-sec3-heding2'>Crop farming project</p>
                                    <p className='prod-sec3-p1'>

                                        CropGen crop health monitoring service utilizes satellite imagery and advanced algorithms to analyze vegetation indices like NDVI, EVI, NRI, NDWI, SOC, SAVI, SMI etc. and provide farmers with real-time insights into the health and productivity of their crops. By tracking changes in these indices over time, CropGen can identify areas of stress, nutrient deficiencies, water imbalances, and potential pest or disease outbreaks, enabling farmers to take timely corrective actions and optimize resource utilization</p>

                                </div>



                            </div>
                        </div>


                    </div>


                </div>





            </section>
 */}


            <section className='bit-prod-sec4'>

                <div className='row m-0 p-0'>
                    <div className='col-lg-6 col-md-12' id='bit-product-for-view-prd'>
                        <div className='bit-prod-sec4-left-main-div'>
                            <p className='bit-prod-sec4-left-main-div-title1'>Salesoptim</p>
                            <p className='bit-prod-sec4-left-main-div-p1'>SalesOptim is a cutting-edge CRM application designed to elevate your sales processes and drive business growth.</p>


                            <ul className='bit-prod-sec4-left-main-div-ul'>
                                <li>auto call dialer</li>
                                <li>Automated lead</li>
                                <li>Advance Live Tracking</li>
                                <li>Employee Dashboard</li>
                                <li>Business Dashboard</li>
                           
                            </ul>
                            <p className='bit-prod-sec4-left-main-div-p1'>Salesoptim helps users reach potential customers.</p>
                            <a href='https://salesoptim.com/' target='_blank' className='bit-prod-sec4-left-main-btn1'>View More</a>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 p-0 bit-prod-sec4-right-main-div'>

                        <img src='images/bit-prd-sec4-img1.png' alt="" className='bit-prd-sec4-img1' />
                    </div>

                </div>

            </section>
            <section className='bit-prod-sec5'>
                <div className='row p-0 m-0'>
                    <div className='col-lg-5 col-md-12'>

                        <div className='bit-prod-sec5-left-col-div'>
                            <div className='bit-prod-sec5-left-main-div'>
                                <p className='bit-prod-sec5-left-main-div-title1'>ERP</p>
                                <hr className='bit-prod-sec5-left-main-div-hr1'></hr>
                                <p className='bit-prod-sec5-left-main-div-p1'>Small & Medium businesses use ERP software to improve communication across departments and optimize business processes by providing easy visibility of all operations.</p>
                                <p className='bit-prod-sec5-left-main-div-p2'>ERP software also helps small businesses integrate both their financial information and customer information into one system.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12 p-0 bit-prod-sec5-right-main-div'>

                        <img src='images/bit-prd-sec5-img1.png' alt="" className='bit-prod-sec5-right-main-div-img1' data-aos="zoom-in"
                            
                             />
                    </div>
                </div>

            </section>



            <section className='bit-prod-sec6'>
                <div className='row p-0 m-0'>
                    <div className='col-lg-5 col-md-12 bit-prod-sec6-left-col'>
                        <div className='bit-prod-sec6-left-main-div'>
                            <p className='bit-prod-sec6-left-main-div-title1'>HRM</p>
                            <hr className='bit-prod-sec6-left-main-div-hr1'></hr>

                            <p className='bit-prod-sec6-left-main-div-p1'>Our HRM application seamlessly manages employee records, tracks attendance, monitors leave, and maintains detailed daily activity logs.</p>
                            <p className='bit-prod-sec6-left-main-div-p2'>With user-friendly interfaces, it ensures efficient record-keeping and simplifies salary management. </p>
                            <p className='bit-prod-sec6-left-main-div-p3'>Enhance workforce productivity and streamline HR processes with our comprehensive solution, tailored to meet your organization's needs.</p>
                        </div>
                    </div>
                    <div className='col-lg-7 col-md-12 col- bit-prod-sec6-right-main-div'>
                        <img src='images/imagesbit-prd-sec6-img3.png' alt="" className='bit-prod-sec6-right-main-div-img1' data-aos="zoom-in-up"  />

                    </div>
                </div>
            </section>

            <section className='prod-sec3 '>


                <div className='' id='prod-sec3-listdiv'>
                    <p className='prod-sec3-heding1'>Other Services We Offer</p>

                    <div className='prod-sec3-main-div'>

                        <div className='circle1-main-prdsec3' data-aos="fade-down"  >
                            <div className='circle1-prdsec3' >


                                <i class="fa-solid fa-laptop-code" ></i>

                            </div>
                            <p>Web Development</p>
                        </div>
                        <div><img src='../images/line.png' id='line1-prdsec3' data-aos="fade-up" /></div>

                        <div className='circle2-main-prdsec3' data-aos="fade-down" >
                            <div className='circle1-prdsec3'>

                                <i class="fa-solid fa-mobile-screen-button" ></i>

                            </div>
                            <p>Mobile<br></br> Development</p>
                        </div>

                        <div><img src='../images/line.png' id='line1-prdsec3' data-aos="fade-up"  /></div>

                        <div className='circle3-main-prdsec3' data-aos="fade-down" >
                            <div className='circle1-prdsec3'>
                                {/* <img src='../images/circle.png' /> */}
                                <i class="fa-brands fa-codepen img-fluid" ></i>
                            </div>
                            <p>UI/UX Designer</p>
                        </div>


                        <div>
                            <img src='../images/line.png' id='line1-prdsec3' data-aos="fade-up" /></div>

                        <div className='circle4-main-prdsec3' data-aos="fade-down" >
                            <div className='circle1-prdsec3'>
                                <i class="fa-solid fa-address-card" ></i>

                            </div>
                            <p>Recruitment</p>
                        </div>
                    </div>


                </div>
            </section>



        </div>
    )
}

export default Product



