import React from 'react'
import './css/Career.css'
import { useEffect, use } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

import bit_career_hire_search_icon from './images/bit-career-search-icon1.png';
import bit_career_hire_search_error_img from './images/bit-career-hire-search-error-image.png';

import bit_career_hire_box7_img from './images/bit-career-hiring-box7-img.png';
import bit_career_hire_box8_img from './images/bit-career-hiring-box8-img.png';
import bit_career_hire_box9_img from './images/bit-career-hiring-box9-img.png';
import bit_career_hire_box10_img from './images/bit-career-hiring-box10-img.png';
import bit_career_hire_box11_img from './images/bit-career-hiring-box11-img.png';


function Career() {



    const [searchQuery, setSearchQuery] = useState('');
    const [filterLocation, setFilterLocation] = useState('all');
    const [noResults, setNoResults] = useState(false);

    // Function to filter job boxes based on location and search query
    const filterJobs = () => {
        const jobBoxes = document.querySelectorAll('.job-box');
        let found = false; // To track if any box is displayed

        jobBoxes.forEach((box) => {
            const title = box.querySelector('h4').textContent.toLowerCase();
            const locationClass = box.classList.contains(filterLocation) || filterLocation === 'all';
            const searchMatch = title.includes(searchQuery.toLowerCase());

            if (locationClass && searchMatch) {
                box.style.display = 'flex'; // Or 'block' depending on your layout
                found = true;
            } else {
                box.style.display = 'none';
            }
        });

        // Show "No results found" message if no boxes are displayed
        setNoResults(!found);
    };

    // Update filters whenever searchQuery or filterLocation changes
    useEffect(() => {
        filterJobs();
    }, [searchQuery, filterLocation]);

    // Update filterLocation when dropdown item is clicked
    // Update filterLocation when dropdown item is clicked
    // Update filterLocation when dropdown item is clicked
    const handleSelectChange = (event) => {
        setFilterLocation(event.target.value);
    };







    // const filterJobs = (location) => {
    //     const jobBoxes = document.querySelectorAll('.job-box');
    //     jobBoxes.forEach((box) => {
    //         if (location === 'all' || box.classList.contains(location)) {
    //             box.style.display = 'block'; 
    //         } else {
    //             box.style.display = 'none';
    //         }
    //     });
    // };


    // useEffect(() => {
    //     const indiaButton = document.getElementById('dropdownIndia');
    //     const ukButton = document.getElementById('dropdownUk');
    //     const allButton = document.getElementById('dropdownAll');

    //     indiaButton.addEventListener('click', () => filterJobs('india'));
    //     ukButton.addEventListener('click', () => filterJobs('uk'));
    //     allButton.addEventListener('click', () => filterJobs('all'));

    //     return () => {
    //         indiaButton.removeEventListener('click', () => filterJobs('india'));
    //         ukButton.removeEventListener('click', () => filterJobs('uk'));
    //         allButton.removeEventListener('click', () => filterJobs('all'));
    //     };
    // }, []);



    // const [searchQuery, setSearchQuery] = useState('');
    // const [filterLocation, setFilterLocation] = useState('all');

    // // Function to filter job boxes based on location and search query
    // const filterJobs1 = () => {
    //     const jobBoxes = document.querySelectorAll('.job-box');
    //     jobBoxes.forEach((box) => {
    //         const title = box.querySelector('h4').textContent.toLowerCase();
    //         const locationClass = box.classList.contains(filterLocation) || filterLocation === 'all';
    //         const searchMatch = title.includes(searchQuery.toLowerCase());
    //         if (locationClass && searchMatch) {
    //             box.style.display = 'flex'; // Or 'block' depending on your layout
    //         } else {
    //             box.style.display = 'none';
    //         }
    //     });
    // };

    // // Adding event listeners for search and location filters
    // useEffect(() => {
    //     filterJobs1();
    // }, [searchQuery, filterLocation]);



    useEffect(() => {
        document.title = 'career';


    }, []);

    return (
        <div>
<Helmet>
                        <title>Carreer at Brightlink Info Technologies | Join Our CRM</title>
                        <meta
                            name="description"
                            content="Explore exciting carreer opportunities at Brightlink Info
Technologies. Join our team of innovators and experts in IT solutions, digital
marketing, web development, mobile apps, CRM, and recruitment services. Grow
with us and make an impact!"
                        />
                        <meta
                            name="keywords"
                            content=" IT blog, tech insights, industry updates, Brightlink Info Technologies, technology news"
                        />
                        <link rel="canonical" href="https://yourwebsite.com/home" />
                        <meta name="robots" content="index, follow" />
                        <meta name="author" content="Brightlink Info Technologies" />
                        <meta name="publisher" content="Brightlink Info Technologies" />
                    </Helmet>


            <section className='sectioncr1 d-flex align-items-center'>
                <div className='m-auto col-9' id='sectioncr1-title1'>
                    <p>Our Reinvention</p>
                    <p> Starts With You.</p>
                </div>
            </section>


            {/*             
            <section className='sectioncr2 d-flex align-items-center'>
                <div className='container'>
                    <div className='row w-100'>
                        <div className='col-md-6 text-right '>
                            <h2>Recruitment Portal</h2>
                        </div>
                        <div className='col-md-6'>
                            <div class="input-group mb-3 d-flex align-items-center h-100">
                                <input type="text" class="form-control" placeholder="keyword skill, job title, seniority,etc" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button class="btn" type="button" id="button-addon2" style={{ background: "#033056", color: "white" }}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section class="sectioncre3 d-flex align-items-center">
                <div class="container py-5">
                    <div class="row" id=''>
                        <h1 className='sectioncr2-head1'>We're  Hirings</h1>


                        <div className='row m-0 p-0 w-100'>
                            <div className='col-lg-3'>
                                {/* <div className="dropdown h-100">
                                    <button className="btn dropdown-toggle bit-career-hire-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Dropdown button
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" onClick={() => handleDropdownClick('india')}>India</a>
                                        <a className="dropdown-item" onClick={() => handleDropdownClick('uk')}>UK</a>
                                        <a className="dropdown-item" onClick={() => handleDropdownClick('all')}>All</a>
                                    </div>


                                    <select class="custom-select" id="inputGroupSelect01">
                                        <option selected>Choose...</option>
                                        <option value="1"onClick={() => handleDropdownClick('india')}>India</option>
                                        <option value="2"onClick={() => handleDropdownClick('uk')}>UK</option>
                                        <option value="3"onClick={() => handleDropdownClick('all')}>All</option>
                                    </select>
                                </div> */}

                                <select
                                    className="custom-select h-100"
                                    id="inputGroupSelect01"
                                    value={filterLocation}
                                    onChange={handleSelectChange}
                                >
                                    <option value="all">All</option>
                                    <option value="india">India</option>
                                    <option value="uk">UK</option>
                                </select>
                            </div>
                            <div className='col-lg-9'>
                                <div className='row m-0 p-0 w-100 bit-career-hire-row1'>

                                    <div className='bit-career-hire-row1-col1'>

                                        <img src={bit_career_hire_search_icon} id='sectioncre3-box1-img1' />
                                    </div>

                                    <div className="bit-career-hire-row1-col2">
                                        <input
                                            type="text"
                                            className="bit-career-hire-row1-col2-inpt"
                                            placeholder="Keyword skill, job title, seniority, etc"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                    <div className="bit-career-hire-row1-col3">
                                        <a className="bit-career-hire-row1-col3-btn" onClick={filterJobs}>Search</a>
                                    </div>
                                </div>

                                <div className="bit-career-hire-row1-col4-for-mobile">
                                    <a className="bit-career-hire-row1-col3-btn-for-mobile" onClick={filterJobs}>Search</a>
                                </div>
                            </div>
                        </div>



                        <div class="row col-md-12 mb-4 p-0 h-100 m-0" >

                       

                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india  Android Developer bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-4 ">
                                        <img src="/images/job2.png" id='sectioncre3-box2-img1' />
                                        <h4 className='mt-4 pt-3' id='sectioncre3-box2-title'>Android Developer</h4>
                                        <div class="text-right" id='sectioncre3-box2-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact'>Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Php Developer Internship bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-5 mt-4">
                                        <img src={bit_career_hire_box10_img} id='sectioncre3-box9-img1' />
                                        <h4 className='mt-5' id='sectioncre3-box9-title'>Front End Developer</h4>
                                        <div class="text-right" id='sectioncre3-box9-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' >Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Php Developer Internship bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-5 mt-4">
                                        <img src={bit_career_hire_box11_img} id='sectioncre3-box11-img1' />
                                        <h4 className='mt-5' id='sectioncre3-box11-title'>Devops Engineer</h4>
                                        <div class="text-right" id='sectioncre3-box11-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' >Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box uk india BusinessDevelopmentManager bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-4">
                                        <img src="/images/job1.png" id='sectioncre3-box1-img1' />
                                        <h4 className='text-start pl-4 ' id='sectioncre3-box1-title'>Business Development Manager</h4>
                                        <div class="text-right" id='sectioncre3-box1-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' id=''>Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Technical Lead bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd">
                                    <div class="card-body ps-">
                                        <img src="/images/job3.png" id='sectioncre3-box3-img1' />
                                        <h4 className='mt-5 pt-2' id='sectioncre3-box3-title'>Technical Lead</h4>
                                        <div class="text-right" id='sectioncre3-box3-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' >Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Senior HR Manager bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-5">
                                        <img id='sectioncre3-img4' src="/images/job4.png" />
                                        <h4 className='mt-5' id='sectioncre3-box4-title'>Senior HR Manager</h4>
                                        <div class="text-right" id='sectioncre3-box4-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact'>Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Business Operation Head bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body h-100 ps-4 mt-4">
                                        <img src="/images/job5.png" id='sectioncre3-box5-img1' />
                                        <h4 className='mt-5' id='sectioncre3-box5-title'>Business Operation Head</h4>
                                        <div class="text-right" id='sectioncre3-box5-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact'>Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Php Developer Internship">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-5 mt-4">
                                        <img src="/images/job6.png" id='sectioncre3-box6-img1' />
                                        <h4 className='mt-5' id='sectioncre3-box6-title'>Php Developer Internship</h4>
                                        <div class="text-right" id='sectioncre3-box6-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' >Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Php Developer Internship bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-5 mt-4">
                                        <img src={bit_career_hire_box7_img} id='sectioncre3-box7-img1' />
                                        <h4 className='mt-5' id='sectioncre3-box6-title'>Telecaller</h4>
                                        <div class="text-right" id='sectioncre3-box7-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' >Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 w-100 p-5 job-box india Php Developer Internship bit-career-hover-zoom">
                                <div class="card h-100 bit-carrer-we-hires-crd" >
                                    <div class="card-body ps-5 mt-4">
                                        <img src={bit_career_hire_box9_img} id='sectioncre3-box8-img1' />
                                        <h4 className='mt-5' id='sectioncre3-box8-title'>Sales Coordinator</h4>
                                        <div class="text-right" id='sectioncre3-box8-apply'>
                                            <a class="w-100 bit-career-hires-box-apply-btn" href='/contact' >Apply now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        {/* <div class="row col-md-12 mb-4 p-0 h-100 ">


                        </div> */}



                    </div>

                    <div className="row col-md-12 mb-4 p-0 h-100">
                        {/* Your job boxes */}
                        {noResults && (
                            <div className="no-results">
                                <img src={bit_career_hire_search_error_img} id='' className='bit-career-hire-search-error-img' />
                                <p className='bit-career-hire-search-error-p1'>No results found</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>


            {/* <section className='sectioncr5 align-items-center'>
                <div className='container mt-5 mb-5'>
                    <h1 className='seccr5-head1'>Learn More</h1>
                    <div class="container ">

                        <div class="row" id='sectioncr5-boxs'>
                            <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 text-center p-1" data-aos="fade-up" data-aos-duration="1500" >
                                <div className='divborder'>
                                    <img src="/images/Rectangle142.png" alt="" className="img-thumbnail border-0" />
                                    <a className='text-primary' >Why Join BrightLink</a>
                                </div>
                            </div>
                            <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 text-center p-1" data-aos="fade-down" data-aos-duration="1500">
                                <div className='divborder'>
                                    <img src="/images/Rectangle143.png" alt="" className="img-thumbnail border-0" />
                                    <a className='text-primary'>Professions</a>
                                </div>
                            </div>
                            <div class="col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 text-center p-1" data-aos="fade-up" data-aos-duration="1500">
                                <div className='divborder'>
                                    <img src="/images/Rectangle144.png " alt="" className="img-thumbnail  border-0" />
                                    <a className='text-primary'>Join Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 */}


            {/* <section className='sectioncr6 d-flex align-items-center h-100'>
                <div className='col-md-8 ml-5 mt-5 h-100'>
                    <div class="row boxsh">
                        <div class="row col-md-12 mb-4 p-0">
                            <div class="col-md-8 w-100 p-0">
                                <div class="card h-100" >
                                    <div class="card-body p-5 h-100">
                                        <h5 class="">CRM</h5>
                                        <p><b>Brightlink Softwares offers an innovative Lead Management Software designed to streamline and optimize the process of acquiring and nurturing potential customers. This robust software empowers businesses to efficiently capture, track, and manage leads throughout the entire sales cycle. With its user-friendly interface, the Brightlink Lead Management Software enables teams to prioritize and assign leads, ensuring a seamless and organized workflow.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 w-100 p-0">
                                <img src="/images/Rectangle172.png" alt="" className='sec6img' />
                            </div>
                        </div>
                    </div>
                    <div class="row boxsh">
                        <div class="row col-md-12 mb-4 p-0">
                            <div class="col-md-8 w-100 p-0">
                                <div class="card h-100" >
                                    <div class="card-body p-5 h-100">
                                        <h5 class="">ERP</h5>
                                        <p><b>Small & Medium businesses use ERP software to improve communication across departments and optimize business processes by providing easy visibility of all operations.ERP software also helps small businesses integrate both their financial information and customer information into one system.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 w-100 p-0">
                                <img src="/images/Rectangle172.png" alt="" className='sec6img' />
                            </div>
                        </div>
                    </div>
                    <div class="row boxsh">
                        <div class="row col-md-12 mb-4 p-0">
                            <div class="col-md-8 w-100 p-0">
                                <div class="card h-100" >
                                    <div class="card-body p-5 h-100">
                                        <h5 class="">HRM</h5>
                                        <p><b>Our HRM application seamlessly manages employee records, tracks attendance, monitors leave, and maintains detailed daily activity logs. With user-friendly interfaces, it ensures efficient record-keeping and simplifies salary management. Enhance workforce productivity and streamline HR processes with our comprehensive solution, tailored to meet your organization's needs.</b></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 w-100 p-0">
                                <img src="/images/Rectangle172.png" alt="" className='sec6img' />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className='sectioncr7 vh-100 position-relative'>
                <div class="container h-75 p-0 position-absolute bottom-0 end-0">
                    <div class="h-100 w-100">
                        <div class="h-100 w-100 d-flex">
                            <div class="col-md-5 p-0 position-relative z-3">
                                <div class="position-absolute top-50 start-100 translate-middle">
                                <div class="card ">
                                    <div class="card-body">
                                        <h5 class="card-title">OUR PRODUCT</h5>                                
                                    </div>
                                </div>
                                <button className='btn mt-3' style={{background:"#049AF3",color:"white"}}>View all products</button>
                                </div>
                            </div>
                            <img src="/images/Rectangle183.png" alt="" className='img-fluid col-md-7 p-0' />
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default Career