import React from "react";
import './footer.css'
import { Link } from 'react-router-dom';

import bit_footer_twitter_img from '../components/images/bit-twittter-new-icon.png';


const Footer = () => {
    return (
        <>
            <section className="footer" >

                <div className="footer-main">
                    <div className=" part-1  ">
                        <img src="/img/lg.png" alt="" />
                        <p className="">"Our vision is to emerge as a leading force in recruitment, staffing, and web development where innovation thrives, businesses prosper, and individuals excel."</p>
                    </div>

                    <div className=" part-2 ">

                        <h4>Company</h4>
                        <a href="/home" className="nav-link">Home</a>
                        <a href="/about" className="nav-link">About Us</a>
                        <a href="/itservices" className="nav-link">IT Services</a>
                        <a href="/recruitment" className="nav-link">Recruitment</a>
                        <a href="/product" className="nav-link">Product</a>
                        <a href="/blog" className="nav-link">Blog</a>
                        <a href="/career" className="nav-link">Career Page</a>
                        <a href="/contact" className="nav-link">Contact Us</a>

   

                    </div>

                    <div className=" part-3 ">
                        <h3>Locations</h3>
                        <h4>India</h4>
                        <a className="footer-part3-a1" href="https://maps.app.goo.gl/EJqnoxHXtgELnzSw9" target="_blank"><p id="footer-part3-p1"> 1st Floor, Shalini Corner Building, Office No 11, Karve Nagar Pune, Maharashtra 411052
                        </p></a>
                        
                        <a className="footer-part3-a1" href="tel:+918149055001"><p>Indian &nbsp;Number +91&nbsp;8149055001 </p></a>

                        <h4 id="footer-part3-add2">United Kingdom</h4>
                        <p id="footer-part3-p1">LG05, 71-75 Shelton St, London WC2H 9JQ, United Kingdom

                        </p>


                        <p>UK Number&nbsp; +44&nbsp;7448674419</p>
                    </div>

                </div>
                <div className="icn " id="icon">
                    <a className="text-white" href="https://www.linkedin.com/company/brightlink-info-technologies-pvt-ltd/mycompany/verification/" target="_blank">  <i class="fa-brands fa-linkedin "></i></a>

                    <a className="text-white" href="https://m.facebook.com/p/BrightLink-Info-Technologies-Pvt-Ltd-61554362690141/?wtsid=rdr_0Vcw4TY3TFRQqrgaW" target="_blank">  <i class="fa-brands fa-facebook "></i></a>


                    <a className="text-white" href="https://www.instagram.com/brightlink_info_technologies/" target="_blank"> <i class="fa-brands fa-instagram"></i>  </a>

                    <a className="text-white" href="https://x.com/BrightLinkInfo/following" target="_blank"> <img src={bit_footer_twitter_img} alt="" className="bit-footer-twitter-icon" />  </a>
                </div>

                <div>


                </div>



                <div className=" border-top">


                    <p className="fw-bold">©2024 BrightLink Info Technologies Private Limited. All Rights Reserved</p>
                    <p class="privacy">Privacy Policy</p>

                </div>


            </section>





        </>


    )
}
export default Footer;

