import iINS from "./icons/itservises/Mask_group.png";
import iINS1 from "./icons/itservises/Mask_group_1.png";
import iINS2 from "./images/itservises/Rectangle_95.png";
import iINS3 from "./icons/itservises/image_20.png";
import iINS4 from "./icons/itservises/image_24.png";
import iINS5 from "./icons/itservises/image_25.png";
import iINS6 from "./icons/itservises/image_26.png";

import iINS7 from "./images/itservises/Rectangle_43.png";
import iINS8 from "./images/itservises/Rectangle_44.png";
import iINS9 from "./images/itservises/Rectangle_45.png";

import iINS10 from "./icons/itservises/Rectangle_96.png";

import { useEffect } from "react";
import { Helmet } from "react-helmet";
// import Aos from "aos";
// import 'aos/dist/aos.css'


import bit_itservice_sec2_img1 from "./images/itservises/bit-itservice-sec2-img1.png";
import bit_itservice_sec2_img2 from "./images/itservises/bit-itservice-sec2-img2.png";

import bit_itservice_sec2_img3 from "./images/itservises/bit-itservice-sec2-img3.png";
import bit_itservice_sec3_img2 from "./images/itservises/bit-itservice-se3-c-img1.png";
import bit_itservice_sec3_img3 from "./images/itservises/bit-itservice-se3-c-img3.png";

import bit_itservice_sec4_img1 from "./images/itservises/bit_itservice_sec4_img1.png";
import bit_itservice_sec4_img2 from "./images/itservises/bit_itservice_sec4_img2.png";
import bit_itservice_sec4_img3 from "./images/itservises/bit_itservice_sec4_img3.png";
import bit_itservice_sec4_img4 from "./images/itservises/bit_itservice_sec4_img4.png";


import bit_itservice_sec5_img1 from "./images/itservises/bit-itservice-sec5-img1.png";
import bit_itservice_sec5_img2 from "./images/itservises/bit-itservice-sec5-img2.png";

import bit_itservice_sec6_img1 from "./images/itservises/bit-itservice-sec6-img1.png";
import bit_itservice_sec6_img2 from "./images/itservises/bit-itservice-sec6-img2.png";
import bit_itservice_sec6_img3 from "./images/itservises/bit-itservice-sec6-img3.png";
import bit_itservice_sec6_img4 from "./images/itservises/bit-itservice-sec6-img4.png";




const ItServises = () => {

    // useEffect(() => {
    //     Aos.init({ duration: "1000" });
    // }, []);


    useEffect(() => {
        // document.title = 'itServices';


    }, []);
    return (
        <div>

<Helmet>
                        <title>Our Services | IT, Digital Marketing, Web Development, CRM & More</title>
                        <meta
                            name="description"
                            content="Brightlink Info Technologies offers IT solutions, digital
marketing, website development, mobile apps, and CRM services to help businesses
grow and succeed with innovative strategies and technology."
                        />
                        <meta
                            name="keywords"
                            content=" IT services, system integration, network management, business IT solutions, Brightlink Info Technologies"
                        />
                        <link rel="canonical" href="https://yourwebsite.com/home" />
                        <meta name="robots" content="index, follow" />
                        <meta name="author" content="Brightlink Info Technologies" />
                        <meta name="publisher" content="Brightlink Info Technologies" />
                    </Helmet>

            <div class="">
                <div class="bit-itservice-sec1-main">
                    <div class="gradient-text border-0">

                        <p className="bit-itservice-sec1-title1">"WE CONNECT IDEAS TO THE DIGITAL WORLD.’’</p>
                        {/* <p class="p1">" WE CONNECT IDEAS TO THE DIGITAL WORLD </p>
                        <p class="p1"> - BRIGHTLINK INFO TECHNOLOGIES." </p>  */}


                    </div>
                    <div className="bit-itservice-sec1-p1-main">
                        <p className="bit-itservice-sec1-p1">BRIGHTLINK INFO TECHNOLOGIES PVT LTD </p>

                    </div>
                </div>
            </div>

            <div className="bit-itservices-sec2-main">

                <div className="bit-itservices-se2-sub1">


                    <p className="bit-itservices-sec2-main-p1">Explore Our services</p>
                    <p className="bit-itservices-sec2-main-p2">Partner  with us to get access to expert Service</p>

                    <div className="row w-100 m-0 p-0 bit-itservices-sec2-sub1-row1">
                        <div className="col-lg-4 bit-itservice-disp-fordesktop" data-aos="fade-down" >
                            <img src={bit_itservice_sec2_img1} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>
                        <div className="col-lg-8 bit-itservices-sec2-sub1-row1-col2" data-aos="fade-up" >

                            <p className="bit-itservices-sec2-sub1-row1-col2-p1">Web Application Development</p>
                            <p className="bit-itservices-sec2-sub1-row1-col2-p2">We create and maintaining websites, including design, programming, and database management. It covers both front-end and back-end tasks.</p>
                        </div>

                        <div className="col-lg-4 bit-itservice-disp-formobile" >
                            <img src={bit_itservice_sec2_img1} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>
                    </div>

                    <div className="row w-100 m-0 p-0 bit-itservices-sec2-sub1-row1">

                        <div className="col-lg-8 bit-itservices-sec2-sub1-row1-col2" data-aos="fade-down" >

                            <p className="bit-itservices-sec2-sub1-row1-col2-p1">Mobile Application Development</p>
                            <p className="bit-itservices-sec2-sub1-row1-col2-p2">We also provide mobile app development that is essential in the digital era due to the widespread use of smartphones and tablets. </p>
                        </div>
                        <div className="col-lg-4" data-aos="fade-up" >
                            <img src={bit_itservice_sec2_img2} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                    </div>
                </div>


                <div className="bit-itservices-sec3">

                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">
                        <div className="col-lg-4 bit-itservice-disp-fordesktop" data-aos="fade-down" >
                            <img src={bit_itservice_sec2_img3} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                        <div className="col-lg-8 bit-itservices-sec3-sub1-row1-col2" data-aos="fade-up" >

                            <p className="bit-itservices-sec3-row1-col2-p1">UX/UI Design </p>
                            <p className="bit-itservices-sec3-row1-col2-p2">We provide graphics interface and experience design which is about creating visually appealing and user-friendly interfaces for digital products.

                            </p>
                        </div>

                        <div className="col-lg-4 bit-itservice-disp-formobile" data-aos="fade-down" >
                            <img src={bit_itservice_sec2_img3} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>
                    </div>

                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">

                        <div className="col-lg-8 bit-itservices-sec3-sub1-row1-col2" data-aos="fade-down" >

                            <p className="bit-itservices-sec3-row1-col2-p1">Graphic Design</p>
                            <p className="bit-itservices-sec3-row1-col2-p2">We help to increase brand identity and user experience, attracting clients and conveying professionalism. It also aids in clear communication, making complex information more accessible.</p>
                        </div>
                        <div className="col-lg-4" data-aos="fade-up" >
                            <img src={bit_itservice_sec3_img2} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>


                    </div>



                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">
                        <div className="col-lg-4 bit-itservice-disp-fordesktop" data-aos="fade-down" >
                            <img src={bit_itservice_sec3_img3} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                        <div className="col-lg-8 bit-itservices-sec3-sub1-row1-col2" data-aos="fade-up" >

                            <p className="bit-itservices-sec3-row1-col2-p1">IT Sales & Support</p>
                            <p className="bit-itservices-sec3-row1-col2-p2">Let us manage your IT infrastructure, so you can focus on your core business. We offer proactive monitoring, maintenance, and support to keep your systems running smoothly and securely.</p>
                        </div>

                        <div className="col-lg-4 bit-itservice-disp-formobile" data-aos="fade-down" >
                            <img src={bit_itservice_sec3_img3} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>
                    </div>

                </div>




                <div className="bit-itservices-sec4">

                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">

                        <div className="col-lg-8 bit-itservices-sec4-sub1-row1-col2" data-aos="fade-down" >

                            <p className="bit-itservices-sec4-row1-col2-p1">MVP Development</p>
                            <p className="bit-itservices-sec4-row1-col2-p2">We  ensure users can easily adopt and benefit from our solutions. It will effectively solve the main problem & deliver value to customers.</p>
                        </div>
                        <div className="col-lg-4" data-aos="fade-up" >
                            <img src={bit_itservice_sec4_img1} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                    </div>

                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">

                        <div className="col-lg-4 bit-itservice-disp-fordesktop" data-aos="fade-down" >
                            <img src={bit_itservice_sec4_img2} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                        <div className="col-lg-8 bit-itservices-sec4-sub1-row1-col2" data-aos="fade-up" >

                            <p className="bit-itservices-sec4-row1-col2-p1">Ecommerce</p>
                            <p className="bit-itservices-sec4-row1-col2-p2">E-commerce has transformed how we buy and sell goods by allowing transactions to be conducted online through the internet. It encompasses various forms such as digital marketplaces, online retail shops, B2B commerce, and mobile payments with brightlink info technologies pvt.ltd. </p>
                        </div>

                        <div className="col-lg-4 bit-itservice-disp-formobile" data-aos="fade-down" >
                            <img src={bit_itservice_sec4_img2} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                    </div>

                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">

                        <div className="col-lg-8 bit-itservices-sec4-sub1-row1-col2" data-aos="fade-down" >

                            <p className="bit-itservices-sec4-row1-col2-p1">IT Consulting</p>
                            <p className="bit-itservices-sec4-row1-col2-p2">We offer expert guidance on technology strategies and solutions, optimizing IT infrastructure to align with business objectives. Our consultants analyze, recommend improvements, and implement solutions to enhance operational efficiency and gain competitive advantage.


                            </p>
                        </div>
                        <div className="col-lg-4" data-aos="fade-up">
                            <img src={bit_itservice_sec4_img3} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                    </div>


                    <div className="row m-0 p-0 w-100 bit-itservices-sec2-sub1-row1">

                        <div className="col-lg-4 bit-itservice-disp-fordesktop" data-aos="fade-down" >
                            <img src={bit_itservice_sec4_img4} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>

                        <div className="col-lg-8 bit-itservices-sec4-sub1-row1-col2" data-aos="fade-up" >

                            <p className="bit-itservices-sec4-row1-col2-p1">Cyber Security</p>
                            <p className="bit-itservices-sec4-row1-col2-p2">We help in protecting systems, networks, and data from cyber threats. It includes implementing security measures, monitoring for attacks, and responding to incidents. Key areas include network security, data protection, and risk management.</p>
                        </div>
                        <div className="col-lg-4 bit-itservice-disp-formobile" data-aos="fade-down" >
                            <img src={bit_itservice_sec4_img4} alt="" className="bit-itservices-sec2-sub1-row1-col1-img1" />
                        </div>



                    </div>
                </div>
            </div>






            <div className="bit-itservice-sec5main">

                <p className="bit-itservice-sec5main-title1">Your technology partner for innovative and impactful digital solutions</p>

                <div className="row m-0 p-0 w-100 bit-itservice-sec5-row1">

                    <div className="col-lg-6 col-md-12 bit-itservice-sec5-row1-col1" data-aos="fade-down" >
                        <img src={bit_itservice_sec5_img1} alt="" className="bit-itservice-sec5-row1-img1" />
                    </div>
                    <div className="col-lg-6 col-md-12 bit-itservice-sec5-row1-col1" data-aos="fade-down" >

                        <p className="bit-itservice-sec5-row1-col1-p1">Brightlink Info Technologies offers cutting-edge IT services to elevate your business to new heights. With a focus on innovation, our team delivers customized solutions that align with your unique goals and challenges. From robust software development to seamless network integration, we empower organizations with scalable and secure IT infrastructure. </p>
                    </div>

                </div>

                <div className="row m-0 p-0 w-100 bit-itservice-sec5-row2">

                    <div className="col-lg-6 col-md-12 bit-itservice-sec5-row2-col1 bit-itservices-sec5-disp-formobile" data-aos="fade-up" >
                        <img src={bit_itservice_sec5_img2} alt="" className="bit-itservice-sec5-row2-img1" />
                    </div>

                    <div className="col-lg-6 col-md-12 bit-itservice-sec5-row1-col1" data-aos="fade-up" >

                        <p className="bit-itservice-sec5-row1-col1-p1">Our experts leverage the latest technologies to optimize efficiency, enhance cybersecurity, and drive digital transformation. Trust Brightlink Info Technologies for reliable, forward-thinking IT services that propel your business into the future. Experience unparalleled support and expertise as we navigate the ever-evolving landscape of information technology.</p>
                    </div>

                    <div className="col-lg-6 col-md-12 bit-itservice-sec5-row2-col1 bit-itservices-sec5-disp-fordesktop" data-aos="fade-up" >
                        <img src={bit_itservice_sec5_img2} alt="" className="bit-itservice-sec5-row2-img1" />
                    </div>


                </div>
            </div>


            <div className="bit-itservice-sec6-main">

                <p className="bit-itservice-sec6-main-title1">Who We Work With</p>

                <div className="bit-itservice-sec6-sub">
                    <div className="row m-0 p-0 w-100 bit-itservice-sec6-sub-row">
                        <div className="col-lg-3 bit-itservice-sec6-sub-col1" data-aos="zoom-in" >
                            <img src={bit_itservice_sec6_img1} alt=""  className="bit-itservice-sec6-sub-col1-img1" />
                            <p className="bit-itservice-sec6-sub-col1-p1">Small Business</p>
                        </div>

                        <div className="col-lg-3 bit-itservice-sec6-sub-col2" data-aos="zoom-in" >
                            <img src={bit_itservice_sec6_img2} alt=""  className="bit-itservice-sec6-sub-col2-img1" />
                            <p className="bit-itservice-sec6-sub-col2-p1">Enterprise</p>
                        </div>

                        <div className="col-lg-3 bit-itservice-sec6-sub-col3" data-aos="zoom-in" >
                            <img src={bit_itservice_sec6_img3} alt=""  className="bit-itservice-sec6-sub-col3-img1" />
                            <p className="bit-itservice-sec6-sub-col3-p1">Agencies</p>
                        </div>

                        <div className="col-lg-3 bit-itservice-sec6-sub-col4" data-aos="zoom-in" >
                            <img src={bit_itservice_sec6_img4} alt=""  className="bit-itservice-sec6-sub-col4-img1" />
                            <p className="bit-itservice-sec6-sub-col4-p1">Startup Business</p>
                        </div>
                    </div>
                </div>


            </div>

            {/* <div class="p-5" style={{ background: "#F0F9FF" }}>
                <div class="container py-4" style={{ marginTop: "" }}>
                    <div class="row ">
                        <div class="col-sm text-center text-white m-2 pb-2" data-aos="zoom-in" style={{ borderRadius: "5%", backgroundColor: "#033056" }}>
                            <img src={iINS} class="icon my-4 "></img>
                            <div class="secitservices2-box1-title1 mb-5 fw-bold" >Network Infrastructure </div>
                            <p class="secitservices2-box1-content mb-4">Building a robust network is the foundation of IT services. From high-speed internet to secure Wi-Fi, it's essential for communication and data transfer.
                            </p>
                        </div>
                        <div class="col-sm text-center text-white m-2" data-aos="zoom-in" style={{ borderRadius: "5%", backgroundColor: "#033056" }}>
                            <img src={iINS1} class="icon my-4"></img>
                            <div class="secitservices2-box2-title1 mb-4 fw-bold">24/7 Support </div>
                            <p class="secitservices2-box2-content">
                                IT services offer round-the-clock technical support. This ensures that if an issue arises, it's addressed promptly, minimizing downtime and potential revenue loss.
                            </p>

                        </div>
                        <div class="col-sm text-center bg-primary text-white m-2" data-aos="zoom-in" style={{ borderRadius: "5%", backgroundColor: "#0178bf" }}>
                            <div class="secitservices2-box3-title1 my-5 fw-bold">Customer Request </div>
                            <p class="secitservices2-box3-content mb-4">One size does not fit in all IT services. Whether you need a complete IT overhaul or specific solutions, there's a customized approach for you:
                            </p>
                            <a href="/contact" value="Find Solution" style={{ color: "blue", backgroundColor: "white" }} class="btn btn-light rounded-pill mb-3">Find Solution</a>
                        </div>
                    </div>
                </div>
            </div>




            <div class="border" style={{ backgroundColor: "#f0f9ff" }}>

                <div class="container d-flex mt-5 pt-5 mb-5 pb-5">
                    <div class="row">
                        <div class="col-sm">
                            <img src={iINS2} style={{ width: "98%" }} class="secitservices3-img1 netInIm mt-2 mb-2"></img>

                        </div>
                        <div class="col-sm text-start" id="secitservices3-right">
                            <p class="h5 text-primary"><img src={iINS10} class="mx-2" /> Welcome To  BrightLink Info Technologies</p>
                            <p class=" " id="secitservices3-title" >Your Technology Partner For Innovative And Impactful Digital Solutions </p>
                            <p class="secitservices3-right-para1" style={{ lineHeight: "180%", color: "#033056" }}>
                                Brightlink Info Technologies offers cutting-edge IT services to elevate your business to new heights. With a focus on innovation, our team delivers customized solutions that align with your unique goals and challenges. From robust software development to seamless network integration, we empower organizations with scalable and secure IT infrastructure. Our experts leverage the latest technologies to optimize efficiency, enhance cybersecurity and drive digital transformation. Trust Brightlink Info Technologies for reliable, forward-thinking IT services that propel your business into the future. Experience unparalleled support and expertise as we navigate the ever-evolving landscape of information technology.
                            </p>

                        </div>

                    </div>
                </div>

            </div>



            <div style={{}}>
                <div class="text-white text-center py-5" style={{ backgroundColor: "#033056", height: "20%" }}>

                    <p class="h1 mt-5 fw-bold">
                        Our Services
                    </p>
                    <p class="h4 mb-5">
                        Partner  with us to get access to expert Service
                    </p>


                </div>
                <div style={{ backgroundColor: "#f0f9ff" }}>
                    <div class="container" style={{ color: "#033056" }}>
                        <div class="row " id="secitservices4-row1">
                            <div class="col-sm  text-center" id="secitservices4-boxes" data-aos="zoom-in" style={{ borderRadius: "5%", boxShadow: "0 0 10px grey", backgroundColor: "white" }}>
                                <div class="ourSer text-center " style={{ marginTop: "", color: "white", fontWeight: "bold" }}> 01 </div>
                                <div><p class="secitservices4-box1-title my-4">Web Design &  Development </p></div>
                                <div class="pb-3"><p class="h6">BrightLink Info Technologies provides web design and development that are essential for effective websites and web applications.
                                </p>
                                </div>
                            </div>
                            <div class="col-sm text-center" id="secitservices4-boxes" data-aos="zoom-in" style={{ borderRadius: "5%", boxShadow: "0 0 10px grey", backgroundColor: "white" }}>
                                <div class="ourSer text-center" style={{ marginTop: "", color: "white", fontWeight: "bold", background: " #033056" }}>  02 </div>


                                <div> <p class="secitservices4-box2-title  my-4">Mobile Application Development </p></div>
                                <div class=""><p class="h6">
                                    We also provide mobile app development that is essential in the digital era due to the widespread use of smartphones and tablets.
                                </p></div>

                            </div>
                            <div class="col-sm text-center   border" id="secitservices4-boxes" data-aos="zoom-in" style={{ borderRadius: "5%", boxShadow: "0 0 10px grey", backgroundColor: "white" }}>
                                <div class="ourSer text-center" style={{ marginTop: "", color: "white", fontWeight: "bold" }}> 03 </div>

                                <div class="secitservices4-box3-title  my-4">Graphics UI/UX Design </div>
                                <div class="pb-3">
                                    <p class="h6">We provide graphics interface and experience design which is about creating visually appealing and user-friendly interfaces for digital products.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div style={{ backgroundColor: "#f0f9ff", marginTop: "" }} class="py-5">
                <div class="container mt-3">
                    <div class="text-center py-3">
                        <p class="h2" style={{ color: "#033056", fontFamily: "Poppins", fontWeight: "700" }}>
                            Who We Work With
                        </p>
                    </div>
          

                </div>
                <div class="container">
                    <div class="row" id="secitservices5-boxes-row">
                        <div class=" col text-center  text-white m-2" id="secitservices5-box1" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000" >



                            <img src={iINS3} class="secitservices5-img1 mt-2 mb-2"></img>
                            <p class="secitservices5-title1" >Small Business </p>
                        </div>
                        <div class="secitservices5-box2 col text-center  text-white m-2" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                            <img src={iINS4} class="secitservices5-img2 mt-2 mb-2"></img>
                            <p class="secitservices5-title2">Enterprise </p>
                        </div>
                        <div class="secitservices5-box3 col text-center  text-white m-2" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                            <img src={iINS5} class="secitservices5-img3 mt-2 mb-2"></img>
                            <p class="secitservices5-title3">Agencies </p>
                        </div>
                        <div class="secitservices5-box4 col text-center text-white m-2" data-aos="flip-left"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                            <img src={iINS6} class="secitservices5-img4 mt-2 mb-2"></img>
                            <p class="secitservices5-title4">Startup Business </p>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ backgroundColor: "#033056" }} class="py-5">
                <div class="container">
                    <div class="text-center text-white py-5">
                        <p class="h1 fw-bold">
                            IT Solutions & Projects
                        </p>
                        <p class="h5" id="secitservices6-content1">
                            Education is the process which aids learning, acquiring knowledge and skills to one’s character
                        </p>
                    </div>

                </div>


                <div class="container text-start">
                    <div class="row ">
                        <div class="col-sm" data-aos="zoom-in-right">
                            <img src="" class="mt-2 mb-2"></img>
                            <div class="">
                                <div>
                                    <img src={iINS7} style={{ width: "98%" }} class="mt-2"></img>
                                </div>
                                <div class="uderimgsec text-white d-flex align-items-center">
                                    <img src="./img/itservice/sec4/img1.png"></img>

                                    <p class="h5">Software Development</p>
                            
                                </div>
                            </div>
                            <p class="h6 text-white mt-3" id="secitservices6-box1-para1">Software development is the systematic process of designing, coding, testing, and maintaining software applications to meet specific requirements. It involves a collaborative effort of skilled professionals to create functional and efficient solutions that address various technological needs.

                            </p>
                            <div className="secitservices6-box1-a-maindiv"><a className="fw-bold text-primary " id="secitservices6-box1-a">View details</a>
                            </div>

                        </div>
                        <div class="col-sm  text-white" data-aos="zoom-in">
                            <img src="" class="mt-2 mb-2"></img>
                            <div class="">
                                <div>
                                    <img src={iINS8} style={{ width: "98%" }} class="mt-2 "></img>
                                </div>
                                <div className="uderimgsec d-flex align-items-center">
                                    <img src="./img/itservice/sec4/img2.png"></img>

                                    <p class="h5 ">Android Development </p>

                                </div>
                            </div>
                            <p class="h6 mt-3" id="secitservices6-box2-para1">Android development is the creation of applications and software tailored for devices running the Android operating system, encompassing smartphones, tablets, and other smart devices.</p>
                            <div className="secitservices6-box2-a-maindiv">

                                <div className="secitservices6-box2-a-maindiv">
                                    <a className="fw-bold text-primary" id="secitservices6-box2-a">View details</a></div>
                            </div>
                        </div>
                        <div class="col-sm  text-white" data-aos="zoom-in">
                            <img src="" class="mt-2 mb-2"></img>
                            <div class="">
                                <div>
                                    <img src={iINS9} style={{ width: "98%" }} class="mt-2 "></img>
                                </div>
                                <div className="uderimgsec d-flex align-items-center">
                                    <img src="./img/itservice/sec4/img3.png"></img>
                                    <div>
                                        <p class="h5 ">Web Application </p>
                                 

                                    </div>
                                </div>
                            </div>
                            <p class="h6 mt-3" id="secitservices6-box3-para1" >A web application is a software application that runs on web browsers, providing users with interactive and dynamic content over the internet. It utilizes a combination of client-side and server-side technologies to deliver a responsive and user-friendly experience.

                            </p>
                            <div className="secitservices6-box3-a-maindiv">

                                <a className="fw-bold text-primary" id="secitservices6-box3-a">View details</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}



            <section className="itservices-sec5">

                <div className="card bg-transparent border-0">
                    <div className="card-body pt-5">
                        <p className="tit1"> Our Partners</p>
                        <div className=" row d-flex justify-content-center">

                            <div className="col-md-3" data-aos="zoom-in-up">
                                <img src="./img/itservice/sec5/img1.png"></img>
                            </div>

                            <div className="col-md-3" data-aos="zoom-in-down">
                                <img src="./img/itservice/sec5/img2.png"></img>

                            </div>

                            <div className="col-md-3" data-aos="zoom-in-up">
                                <img src="./img/itservice/sec5/img3.png"></img>

                            </div>

                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}

export default ItServises;