import { useEffect, useState } from 'react'
import './css/About.css'
import Header from './Header'
import { Helmet } from 'react-helmet'
// import Aos from 'aos'
import { type } from '@testing-library/user-event/dist/type'
import ClipLoader from "react-spinners/ClipLoader";
import { HashLoader } from 'react-spinners';


// import bit_about_our_story_imng from "./images/home/bit-about-our-strory-lft-img.png";
import bit_about_our_story_imng from "./images/bit-about-our-strory-lft-img.png";

import bit_about_explorebit_img1 from "./images/bit-about-exploreBIt-img1.png";
import bit_about_explorebit_img2 from "./images/bit-about-exploreBIt-img2.png";
import bit_about_explorebit_img3 from "./images/bit-about-exploreBIt-img3.png";

import bit_about_our_vision_img1 from "./images/bit-about-our-vision-right-img1.png";
import bit_about_our_misson_img1 from "./images/bit-about-our-mission-left-img1.png";

function About() {


    // useEffect(() => {
    //     document.title = 'aboutUs';


    // }, []);


    const [count, setCount] = useState(0);

    useEffect(() => {
        const targetCount = 200;
        const duration = 4000; // 4 seconds
        const interval = 20; // Update every 20 milliseconds
        const steps = duration / interval;
        const stepSize = targetCount / steps;

        let currentCount = 0;
        const timer = setInterval(() => {
            currentCount += stepSize;
            setCount(Math.min(Math.ceil(currentCount), targetCount));

            if (currentCount >= targetCount) {
                clearInterval(timer);
            }
        }, interval);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []);


    const [count1, setCount1] = useState(0);

    useEffect(() => {
        const targetCount = 1000; // Adjust the target count as needed
        const duration = 4000; // 4 seconds
        const interval = 20; // Update every 20 milliseconds
        const steps = duration / interval;
        const stepSize = targetCount / steps;

        let currentCount = 0;
        const timer = setInterval(() => {
            currentCount += stepSize;
            setCount1(Math.min(Math.ceil(currentCount), targetCount));

            if (currentCount >= targetCount) {
                clearInterval(timer);
            }
        }, interval);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []);
    return (







        <>

<Helmet>
                        <title>IT, Digital Marketing, Web Development, Mobile Apps, CRM Partner</title>
                        <meta
                            name="description"
                            content="Brightlink Info Technologies: A trusted leader in customized
CRM solutions, digital marketing, website development, and IT services.
Empowering businesses with innovative technology to drive growth and success."
                        />
                        <meta
                            name="keywords"
                            content=" IT company, IT solutions provider, software company, Brightlink team"
                        />
                        <link rel="canonical" href="https://yourwebsite.com/home" />
                        <meta name="robots" content="index, follow" />
                        <meta name="author" content="Brightlink Info Technologies" />
                        <meta name="publisher" content="Brightlink Info Technologies" />
                    </Helmet>
            <section id='aboutpg'>



                <section className='sectiona1 h-100' >



                    <>
                        <header class="masthead">
                            <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                                <div class="d-flex justify-content-center">
                                    <div class="text-center" >
                                        <h1 class="mx-auto my-0 text-uppercase" >About Us</h1>
                                        <h2 class="text-white mx-auto mt-2 mb-5">Brightlink Info Technologies Pvt. Ltd. Revolutionizing Your Digital Journey with Expert Solutions.</h2>

                                    </div>
                                </div>
                            </div>
                        </header>
                    </>

                    {/*                 
                <div className='rect1'></div>
                <h1 className='bannerText'>ABOUT US</h1>
                <h2 className='bannerpara'>Brightlink Info Technologies Pvt Ltd. A Premier Recruitment,Staffing, and Web Development Company</h2> */}
                </section>




                <section className='bit-about-ourviion-main'>

                    <div className='bit-about-ourviion-main-first-row'>
                        <div className='row m-0 p-0'>

                            <div className='col-md-5 bit-about-ourviion-main-first-row-col1'>

                                <div >
                                    <p className='bit-about-ourviion-main-first-row-col1-p1'>Our Vision</p>
                                    <p className='bit-about-ourviion-main-first-row-col1-p2'>‘’Our vision is to become the largest and most effective solution provider while creating future leaders using world-class technology and a dynamic workforce.”</p>
                                    <p className='bit-about-ourviion-main-first-row-col1-p3'>“Our goals are to emerge as a leading force in recruitment and staffing where innovations thrive, businesses prosper, and individuals excel."</p>
                                </div>
                            </div>

                            <div className='col-md-7 bit-about-ourviion-main-first-row-col2'  >

                                <img src={bit_about_our_vision_img1} id='sectiona2-left-img1' alt="" class="" />
                            </div>
                        </div>



                    </div>



                    <div className='bit-about-ourviion-main-second-row'>
                        <div className='row m-0 p-0'>

                            <div className='col-md-7' >

                                <img src={bit_about_our_misson_img1} id='sectiona2-left-img2' alt="" class="bit-about-ourviion-main-first-row-col1-img" />
                            </div>
                            <div className='col-md-5 bit-about-ourviion-main-first-row-col1'>

                                <div >
                                    <p className='bit-about-ourviion-main-first-row-col1-p1'>Our Mission</p>
                                    <p className='bit-about-ourviion-main-first-row-col1-p2'>"Our mission is to elevate businesses and individuals to their potential through cutting-edge web development and unmatched staffing solutions. We strive to empower our clients and our team, enabling sustainable growth, excellence, and mutual success in an ever-evolving world."</p>

                                </div>
                            </div>


                        </div>

                    </div>



                </section>

                <section className='sectiona2 h-100 bit-aboutus-ourStory-sec'>
                    <div class="container mt-5 mb-5">
                        <div class="row">
                            <div class="col-md-6">
                                <img src={bit_about_our_story_imng} id='' alt="" class="img-fluid" />
                            </div>

                            <div class="col-md-6 ">
                                <div class=" d-flex flex-column ps-2">
                                    <h1 className='heading'>OUR STORY</h1>
                                    <div class="sectiona2-right-main">

                                        <div className='sectiona2-right-div1' >
                                            <span className='mt-3 mr-2' id='sectiona2-right-title1'>Web development & recruitment:</span><span id='sectiona2-right-content1'> Our dual strengths fuel your success.</span>
                                        </div>

                                        <div className='sectiona2-right-div2 '>
                                            <span className='' id='sectiona2-right-title2'>Proven experience over years  :</span><span id='sectiona2-right-content1'>  The confidence of numerous satisfied clients.</span>
                                        </div>
                                        <div className='sectiona2-right-div3'>                                        <span className='mt-5 ' id='sectiona2-right-title3'>Delivering top : </span><span id='sectiona2-right-content1'>Tier development at competitive cost and budget friendly solutions.
                                        </span>
                                        </div>

                                    </div >
                                    <div class="sectiona2-part2 container text-center">
                                        <div class="row mt-5" >
                                            <div class="col text-start" id='sectiona2-part2-box1'>
                                                <h6 className='mb-3 text-start' style={{ width: "8rem", color: "#033056" }}>Brightlink founded by Miss.Kavita</h6>
                                                <h2 className='sectiona2-part2-h2-1 text-start' >2023</h2>
                                            </div>
                                            <div class="col" id='sectiona2-part2-box1'>
                                                <h6 className='mb-3 text-start' style={{ width: "8rem", color: "#033056" }}>Satisfied clients by our exceptional services</h6>
                                                {/* <h2 className='sectiona2-part2-h2-2 text-start' id="counter"> 200+</h2> */}
                                                <h2 className='sectiona2-part2-h2-2 text-start bit-about-our-sec-count1' id="counter">
                                                    {count}+
                                                </h2>
                                            </div>
                                            <div class="col" id='sectiona2-part2-box1'>
                                                <h6 className='mb-3 text-start' style={{ width: "8rem", color: "#033056" }}>Users all round the world</h6>
                                                {/* <h2 className='sectiona2-part2-h2-3 text-start'>1000+</h2> */}
                                                <h2 className='sectiona2-part2-h2-3 text-start'>
                                                    {count1}+
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className='sectiona3 '>
                    <section class="">
                        <div class="container py-5 h-100">
                            <div class="row d-flex justify-content-start align-items-center h-100 ">
                                <div class="col col-md-9 col-lg-7 col-xl-5 ">
                                    <div class="card">
                                        <div class="card-body p-4">
                                            <div id='sectionabout3-content'>
                                                <h5 class="mb-4 headline">Connect with us to align talent with outstanding oportunities</h5>
                                                <div class="">
                                                    <p class=" mb-1">At Brightlink Information Technologies Pvt Ltd, IT & Recruitment , we understand that attracting and retaining exceptional talent is paramount to achieving your business objectives</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section> */}



                {/* <section className='sectiona4 d-flex align-items-center ' >
                    <section class="sectiona4-con container " >
                        <div class="sectiona4-subcon container py-5 h-100 p-0">
                            <div class="row d-flex justify-content-start align-items-center h-100 p-0">

                                <div class="secabout4-img2 col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-6 p-0 " data-aos="fade-right">
                                    <img src="/images/image12.png" className='img-thumbnail rounded-5 border-0' />
                                </div>

                                <div class="secabout4-middle-box col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-6 p-0" >
                                    <div class=" d-flex flex-column p-4 card " id='' data-aos="fade-down" style={{ background: "#033056", color: "#FFFFFF", borderRadius: "5%", marginBottom: "70%" }}>
                                        <span className='sectiona4-head1'>Our Vision</span>
                                        <span>‘’Our vision is to become the largest and most effective solution provider while creating future leaders using world-class technology and a dynamic workforce.”</span>

                                    </div>

                                </div>

                                <div class="secabout4-img2 col col-md-7 col-lg-6 col-xl-4 col-sm-12 col-xs-12 p-0 " data-aos="fade-left">
                                    <img src="/images/image13.png" className='img-thumbnail rounded-5 border-0' />
                                </div>

                            </div>
                        </div>



                    </section>


                    <div className='secabout4-formeadia'>

                        <p id='secabout4-formeadia-title'>Our Vision</p>
                        <div className='secabout4-formeadia-content' >

                            <p id='secabout4-formeadia-content'>‘’Our Vision Is To Become The Largest And Most Effective Solution Provider While Creating Future Leaders Using World-class Technology And A Dynamic Workforce.”</p>


                        </div>


                    </div>

                </section > */}

                {/* 
                <section className='section4b' >
                    <div class="row m-0 p-0">
                        <div class="col-md-6 col-lg-6 col-xl-6 p-5" style={{ background: "#033056", color: "#FFFFFF" }}>
                            <div class=" d-flex flex-column ps-2" data-aos="fade-right" id='section4bhome-ourmission-box'>
                                <h1 className='heading fw-bold' id='section4bhome-title'>Our Mission</h1>
                                <p id='section4b-contain-p'>‘’Our mission is to elevate business and individuals to their potential through cutting - edge web development and unmatched staffing solutions.’’</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6 p-0">
                            <div class=" d-flex flex-column">
                                <img src="/images/Rectangle75.png" id='section4bhome-' alt="" />
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className='sectiona5 align-items-center bit-about-explore-bit-sec-main'>
                    <div className='container' id='sectionabout5-container1'>
                        <h1 className='heading'>Explore BrightLink</h1>
                        <div class="container ">

                            <div class="row m-0 p-0  w-100">
                                <div class="col-md-12 col-lg-4 col-sm-12 p-0" data-aos="fade-up">
                                    <img src={bit_about_explorebit_img1} alt="" className="img-thumbnail rounded-5 border-0" />
                                    <button type="button" class="btn btn-outline-primary mt-4 mb-2">Career with us</button>
                                </div>
                                <div class="col-md-12 col-lg-4 col-sm-12 p-0 rounded" data-aos="fade-up">
                                    <img src={bit_about_explorebit_img2} alt="" className="img-thumbnail rounded border-0" />
                                    <button type="button" class="btn btn-outline-primary mt-4 mb-2">Our industry</button>
                                </div>
                                <div class="col-md-12 col-lg-4 col-sm-12 p-0 rounded" data-aos="fade-up">
                                    <img src={bit_about_explorebit_img3} alt="" className="img-thumbnail rounded-5 border-0" />
                                    <button type="button" class="btn btn-outline-primary mt-4 mb-2">Our services</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>

    )
}

export default About;